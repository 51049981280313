/* stylelint-disable */
@import '~org/fonts';

@font-face {
    font-family: 'NotoSansThai';
    src: url('../fonts/noto-sans-thai-v20-thai-700.woff2') format('woff2'),
        url('../fonts/noto-sans-thai-v20-thai-700.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai';
    src: url('../fonts/noto-sans-thai-v20-thai-500.woff2') format('woff2'),
        url('../fonts/noto-sans-thai-v20-thai-500.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai';
    src: url('../fonts/noto-sans-thai-v20-thai-regular.woff2') format('woff2'),
        url('../fonts/noto-sans-thai-v20-thai-regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai';
    src: url('../fonts/noto-sans-thai-v20-thai-900.woff2') format('woff2'),
        url('../fonts/noto-sans-thai-v20-thai-900.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}
/* stylelint-enable */
